import axios from "axios"

let service = axios.create({
    baseURL: "/",
    timeout: 20000,
});
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
        'Content-Type': 'application/json', //配置请求头
        "AirScript-Token": "34TQZUWfoChlNMwYSkIWsH"
    }
    let token = localStorage.getItem("token")
    //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    //const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
    //if(token){
    //config.params = {'token':token} //如果要求携带在参数中
    config.headers.token = token; //如果要求携带在请求头中
    //}
    return config
}, error => {
    Promise.reject(error)
})
function throttle(func, delay) {
    let lastCallTime = 0;

    return function (...args) {
        const now = new Date().getTime();
        if (now - lastCallTime >= delay) {
            func.apply(this, args);
            lastCallTime = now;
        }
    };
}
// 3.响应拦截器
service.interceptors.response.use(response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    let data = response.data
    if (data.code === 500) {
        let e = function () {
            console.log("服务器错误！");
        }
        throttle(e(), 3000)
    }
    return response.data
}, error => {
    const msg = () => {
        console.log("请求错误");
    }
    throttle(msg(), 3000)

    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})

export default service;