<template>
  <n-dialog-provider>
    <n-modal-provider>
      <n-message-provider>
        <div :class="isHome ? 'system' : 'loginPage'">
          <span v-if="isAdmin && isHome" class="setting">
            <!-- <n-space justify="space-between"> -->
            <div class="tools"></div>
            <div class="logOut">
              <n-popconfirm @positive-click="logOut" positive-text="确定" negative-text="取消">
                <template #trigger>
                  <n-icon :size="32">
                    <LogOutOutline />
                  </n-icon>
                </template>
                是否确认退出？
              </n-popconfirm>

            </div>
            <!-- </n-space> -->
          </span>
          <router-view />
        </div>
      </n-message-provider>
    </n-modal-provider>
  </n-dialog-provider>
</template>
<script setup>
import { NMessageProvider, NModalProvider, NPopconfirm, NDialogProvider } from 'naive-ui';
import { useStore } from "vuex"
import { useRouter } from 'vue-router';
import { watch, ref, onMounted, computed } from 'vue';
import {
  NIcon,
} from "naive-ui"
import { LogOutOutline } from '@vicons/ionicons5'

const store = useStore()
const router = useRouter()
const user_info = computed(() => store.state.user.user_info)
const isHome = computed(() => store.state.home.isHome)
let isAdmin = ref(false)

const logOut = () => {
  localStorage.removeItem("token")
  router.push("/login")
}
watch(user_info, (newVal) => {
  if (newVal.admin === "1") {
    isAdmin.value = true
  } else {
    isAdmin.value = false
  }
})
</script>
<style>
@import url(./index.css);

@media screen and (max-width:768px) {
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .system {
    width: 100%;
    height: 100%;
  }

  .setting {
    display: none;
  }
}

@media screen and (min-width:768px) {
  #app {
    width: 100%;
    height: 100%;
  }

  .system {
    display: flex;
    justify-content: start;
    flex-direction: row;
  }

  .setting {
    width: var(--pc-left-icons-width);
    /* height: 100%; */
    background-color: #2e2e2e;
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    flex-direction: column;

  }

  .logOut {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

}

.loginPage {
  width: 100%;
  height: 100%;
}
</style>
