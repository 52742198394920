import { _get, _post } from "./http"
import CryptoJS from "crypto-js"

const checkIsAdmin = (phone) => {
    return new Promise((resolve, reject) => {
        _get(`/node/user/checkAdmin?phone=${phone}`).then(
            res => {
                if (res && res.data) {
                    resolve(res.data.isAdmin)
                } else {
                    resolve(false)
                }
            }
        ).catch(err => {
            reject(err)
        })
    })
}
let key = "qwdkshjf9834jsdf";
let iv = "dkfjdfgc987cs6sk";
// 解密数据
const decrypt = (encryptedVal) => {
    /*
      传入的key和iv需要和加密时候传入的key一致  
    */
    // 统一将传入的字符串转成UTF8编码
    return new Promise((resolve, reject) => {
        let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedVal);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
        const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
        let decrypt = CryptoJS.AES.decrypt(srcs, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        // console.log(decryptedStr);
        resolve(decryptedStr)
    })
}

function encryp(data) {
    if (typeof data === "object") {
        // 如果传入的data是json对象，先转义为json字符串
        try {
            data = JSON.stringify(data)
        } catch (error) {
            console.log("error:", error)
        }
    }
    // 统一将传入的字符串转成UTF8编码
    const dataHex = CryptoJS.enc.Utf8.parse(data) // 需要加密的数据
    const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
    const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
    const encrypted = CryptoJS.AES.encrypt(dataHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC, // 加密模式
        padding: CryptoJS.pad.Pkcs7
    })
    let encryptedVal = encrypted.ciphertext.toString()
    return encryptedVal //  返回加密后的值
}
let wssInstance;
const cont = () => {
    let ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET}`);
    wssInstance = ws;
    ws.onopen = () => {
        let user = localStorage.getItem("token") ? decrypt(localStorage.getItem("token")) : null
        user = JSON.parse(user)
        let userid = user ? user.id + "" : ""
        let userInfo = { userid }
        ws.send(JSON.stringify({ ...userInfo }))
        // store.dispatch("setNews", store.state.head.news ? store.state.head.news : {})
        // console.log("open");
    }
    ws.onmessage = (msg) => {
        let result = JSON.parse(msg.data)
        let data
        console.log(result);
        switch (result.stage) {
            case 0:
                break;

        }
    };
    ws.onclose = () => {
        setTimeout(function () {
            cont()
        }, 1500)
        // console.log("close");
        // setTimeout(conectToSocket(), 2000)
    }
    ws.onerror = (error) => {
        console.error("WebSocket error:", error);
    };
}
function sendToUserId(id, message) {
    if (wssInstance) {
        wssInstance.clients.forEach((client) => {
            // console.log(client);
            if (client.readyState === WebSocket.OPEN && client.userid === id) {
                client.send(message);
            }
        });
    }
}

export {
    checkIsAdmin, decrypt, cont, sendToUserId, encryp
}