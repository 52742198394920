export default {
    state() {
        return {
            user_info: {},
            isLogin: false,
        }
    },
    actions: {
        userLogin({ commit }, params) {
            let { user_info, isLogin } = params
            commit("SETISLOGIN", isLogin)
            commit("SETUSER", user_info)
        }
    },
    mutations: {
        SETUSER(state, val) {
            state.user_info = val
        },
        SETISLOGIN(state, val) {
            state.isLogin = val
        }
    }

}