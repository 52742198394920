<template>
    <div class="pc-home">
        <n-split direction="horizontal" min="360px" max="500px" default-size="360px" style="height: 100vh;">
            <template #1>
                <div v-if="isAdmin" class="pc-left">
                    <!-- 联系人列表 -->
                    <span class="pc-home-left">
                        <div class="pc-search">
                            <n-input style="background-color: #e2e2e2;" ref="searchInp" round placeholder="搜索用户"
                                v-model:value="msgObj.search" clearable @input="handleInput">
                                <template #prefix>
                                    <n-icon :component="SearchOutline" />
                                </template>
                            </n-input>
                        </div>
                        <n-scrollbar style="background-color: #e8e7e6; max-height: calc(100% - 36px)">
                            <n-list :clickable="true" hoverable>
                                <n-list-item class="lil" style="padding:8px 6px" v-for="(item, index) in user_list"
                                    :key="item?.id" @click="getDetail(item, index)" @select="itemClick">
                                    <div class="list">
                                        <n-badge :value="getUnReadLength(item?.list)">
                                            <span class="icon">
                                                <n-icon size="32">
                                                    <PersonOutline />
                                                </n-icon>
                                            </span>
                                        </n-badge>
                                        <span class="pc-user">
                                            <div>{{ item?.name }} </div>
                                            <div class="overText">{{ isURL(getEllips(item?.list)) ? '[图片]' :
                                                getEllips(item?.list) }}</div>
                                        </span>
                                    </div>
                                </n-list-item>
                            </n-list>
                        </n-scrollbar>
                    </span>
                </div>
            </template>
            <template #2>
                <n-split direction="horizontal" style="height: 100%" :default-size="0.75" :min="0.25" :max="0.75">
                    <template #1>
                        <n-split direction="vertical" style="height: 100%" :default-size="0.7" :min="0.3" :max="0.7">
                            <template #1>
                                <div v-if="!active || !isAdmin" class="pc-home-top">
                                    {{ custom?.name }}
                                    <!-- <span> -->
                                    &nbsp;
                                    <n-icon v-if="custom?.online === 1" size="16" color="#1be188"
                                        :component="EllipseSharp" />
                                    <!-- </span> -->
                                </div>
                                <!-- 聊天记录 -->
                                <div class="pc-home-content">
                                    <n-scrollbar ref="pcnScroll" :style="{ maxHeight: `${scroll_height}px` }">
                                        <span v-if="moreTpe === 0 && !active && message_list.length > 9">
                                            <n-divider>
                                                <span @click="getMore" class="more blue">查看更多</span>
                                            </n-divider>
                                        </span>
                                        <div class="loading" v-if="moreTpe === 1">
                                            <n-spin size="small" />
                                        </div>
                                        <div v-if="moreTpe === 2" class="more gray">没有更多了</div>
                                        <template v-for="item in message_list" :key="item.id">
                                            <div :class="item.sender_id === curID ? 'item-div-right' : 'item-div-left'">
                                                <div
                                                    :class="item.sender_id === curID ? 'item-head-right' : 'item-head-left'">
                                                    <span>{{
                                                        item.sender_id === curID ? '' : item.sender_name }}</span>&nbsp;
                                                    <span>{{
                                                        convertDateFormat(item.time) }}</span>
                                                </div>
                                                <div v-if="!device"
                                                    :class="item.sender_id === curID ? 'item-cont-right' : 'item-cont-left'"
                                                    style="position: relative;">
                                                    <template v-if="item.type === 2">
                                                        <n-image width='200' :src='item.content' />
                                                    </template>
                                                    <template v-else-if="item.type === 1">
                                                        {{ item.content }}
                                                    </template>
                                                    <span
                                                        :class="item.sender_id === curID ? 'edit-icon-right' : 'edit-icon-left'">
                                                        <n-popover :show-arrow="false"
                                                            :placement="item.sender_id === curID ? 'left-end' : 'right-end'"
                                                            trigger="hover">
                                                            <template #trigger>
                                                                <n-icon style="cursor: pointer;" :size="18">
                                                                    <EllipsisVertical v-if="custom?.id" />
                                                                </n-icon>
                                                            </template>
                                                            <div class="editTools">
                                                                <n-button @click="editMsg(item, 1)" text>
                                                                    <template #icon>
                                                                        <n-icon>
                                                                            <Copy />
                                                                        </n-icon>
                                                                    </template>
                                                                    复制
                                                                </n-button>
                                                            </div>
                                                            <div class="editTools">
                                                                <n-button @click="editMsg(item, 2)" text>
                                                                    <template #icon>
                                                                        <n-icon>
                                                                            <TrashSharp />
                                                                        </n-icon>
                                                                    </template>
                                                                    删除
                                                                </n-button>
                                                            </div>
                                                            <div class="editTools" v-if="ifDisableOfTime(item)">
                                                                <n-button @click="editMsg(item, 3)" text>
                                                                    <template #icon>
                                                                        <n-icon>
                                                                            <ArrowUndoSharp />
                                                                        </n-icon>
                                                                    </template>
                                                                    撤回
                                                                </n-button>
                                                            </div>
                                                        </n-popover>
                                                    </span>
                                                </div>
                                                <div v-else>
                                                    <template v-if="item.type === 2"
                                                        :class="item.sender_id === curID ? 'item-cont-right' : 'item-cont-left'">
                                                        <n-image width='200' :src='item.content' />
                                                    </template>
                                                    <template v-else-if="item.type === 1">
                                                        {{ item.content }}
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                        <div style="height: 20px;"></div>
                                    </n-scrollbar>
                                </div>
                            </template>
                            <template #2>
                                <!-- 输入框 -->
                                <div v-if="!active || !isAdmin" class="pc-home-btm">
                                    <div class="inp-tools">
                                        <n-space :size="20">
                                            <n-popover trigger="click">
                                                <template #trigger>
                                                    <n-icon size="22" style="cursor: pointer;">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 24 24">
                                                            <g fill="none">
                                                                <path
                                                                    d="M6.75 12a.75.75 0 0 0-.748.818C6.282 15.892 8.552 18.5 12 18.5c3.448 0 5.717-2.608 5.998-5.682A.75.75 0 0 0 17.25 12H6.749zM12 17c-2.257 0-3.871-1.48-4.368-3.5h8.736C15.872 15.52 14.258 17 12 17zm3.25-8.25c-.408 0-.71.287-.758.61a.75.75 0 1 1-1.484-.22c.162-1.09 1.123-1.89 2.242-1.89s2.08.8 2.242 1.89a.75.75 0 1 1-1.484.22c-.048-.323-.35-.61-.758-.61zm-7.258.61c.048-.323.35-.61.758-.61c.409 0 .71.287.758.61a.75.75 0 1 0 1.484-.22c-.161-1.09-1.123-1.89-2.242-1.89s-2.08.8-2.242 1.89a.75.75 0 0 0 1.484.22zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2zM3.5 12a8.5 8.5 0 1 1 17 0a8.5 8.5 0 0 1-17 0z"
                                                                    fill="currentColor"></path>
                                                            </g>
                                                        </svg>
                                                    </n-icon>
                                                </template>
                                                <EmojiPicker :display-recent="true" :native="true"
                                                    :disable-skin-tones="true" :hide-search="true"
                                                    :disabled-groups="['animals_nature', 'food_drink', 'activities', 'travel_places', 'objects', 'symbols', 'flags']"
                                                    @select="selectEmoji" />
                                            </n-popover>
                                            <n-upload ref="uploadRef" :show-file-list="false" accept="image/*"
                                                v-model:file-list="file_list" @finish=uploadFinish @error="uploadFail"
                                                @before-upload="beforeUpload"
                                                :trigger-style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }"
                                                :action="`/node/file/upload?user_id=${curID}`">
                                                <n-icon size="22" :component="FolderOutline" />
                                            </n-upload>
                                            <!--截图组件-->
                                            <n-icon size="22" @click="cutScreen" style="cursor: pointer;">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
                                                    <g fill="none">
                                                        <path
                                                            d="M14.879 3.159L11.78 7.926l-.893-1.379l2.734-4.206a.75.75 0 0 1 1.258.818zm-2.384 8.592a3.25 3.25 0 1 1-1.214.885L10 10.665l-1.281 1.971a3.25 3.25 0 1 1-1.214-.885l1.6-2.462l-3.984-6.13A.75.75 0 0 1 6.38 2.34l6.116 9.41zm.196 1.606a1.75 1.75 0 1 0 .019-.014l-.019.014zM6.25 13a1.75 1.75 0 1 0 0 3.5a1.75 1.75 0 0 0 0-3.5z"
                                                            fill="currentColor"></path>
                                                    </g>
                                                </svg>
                                            </n-icon>
                                        </n-space>

                                    </div>
                                    <div class="inp-box">
                                        <textarea @fοcus="inpFocus" class="custom-textarea" v-model="msgObj.content"
                                            autofocus @keydown="handleKeydown"></textarea>
                                    </div>
                                    <div class="submit">
                                        <n-button :loading="sendbtnload" @click="send" strong secondary type="primary">
                                            发送/enter
                                        </n-button>
                                    </div>
                                </div>
                            </template>
                        </n-split>
                    </template>
                    <template #2>
                        <!-- 用户信息和功能 -->
                        <div style="background-color: #f5f5f5;height: 100vh;">
                            <n-card v-if="(isAdmin && !active)" style="background-color: #f5f5f5;" :bordered="false"
                                title="用户信息" size="medium">
                                <template #header-extra>
                                    <n-button type="success" ghost @click="openSelectModal">
                                        提交线索
                                    </n-button>
                                    &nbsp; &nbsp; &nbsp;
                                    <n-button type="success" ghost @click="cancleSubmit" v-if="!edit">
                                        取消
                                    </n-button>
                                    &nbsp; &nbsp; &nbsp;
                                    <n-button @click="submitEdit" v-if="!edit" type="primary">
                                        完成修改
                                    </n-button>

                                    <n-button @click="(() => { edit = !edit })" v-if="edit" type="primary">
                                        修改
                                    </n-button>
                                </template>
                                <n-form ref="infoRef" :model="model" :rules="info_rules">
                                    <n-form-item v-for="item in formList" :label="item.label">
                                        <n-input v-if="!edit" placeholder="" v-model:value="model[item['path']]"
                                            @keydown.enter.prevent />
                                        <span v-if="edit">{{ model[item["path"]] }}</span>
                                    </n-form-item>
                                </n-form>
                            </n-card>
                        </div>
                    </template>
                </n-split>
            </template>
        </n-split>
    </div>
    <div class="home">
        <div class="home-top">
            <span class="top-back">
                <n-icon size="24" v-if="isAdmin" @click="back">
                    <ChevronBackOutline />
                </n-icon>
                <div v-else style="width: 30px;"> </div>
                <n-badge :value="newsCount" :max="99" color="#cdcdcd" :offset="[10, 0]">
                </n-badge>
                &nbsp;
            </span>
            <span class="top-title">{{ name }}
                &nbsp;
                <n-icon v-if="custom?.online === 1" size="16" color="#1be188" :component="EllipseSharp" />
            </span>
            <span class="top-edit">
                <n-icon size="24" v-if="isAdmin">
                    <n-dropdown trigger="click" :options="dropOptions" @select="dropSelect" size="large">
                        <n-button text>
                            <template #icon>
                                <n-icon :size="24">
                                    <Menu></Menu>
                                </n-icon>
                            </template>
                        </n-button>
                    </n-dropdown>
                </n-icon>
                <n-icon v-else size="24" @click="appLogout">
                    <LogOutOutline />
                </n-icon>
            </span>
        </div>
        <div class="home-content" @click="cttClose">
            <n-scrollbar ref="nScroll" :style="{ maxHeight: `${scroll_height}px` }">
                <span v-if="moreTpe === 0 && message_list.length > 9">
                    <n-divider>
                        <span @click="getMore" class="more blue">查看更多</span>
                    </n-divider>
                </span>
                <div class="loading" v-if="moreTpe === 1">
                    <n-spin size="small" />
                </div>
                <div v-if="moreTpe === 2" class="more gray">没有更多了</div>
                <template class="item_div" v-for="(item, index) in message_list" :key="item.id">
                    <div :class="item.sender_id === curID ? 'item-div-right acItem' : 'item-div-left acItem'">
                        <div :class="item.sender_id === curID ? 'item-head-right' : 'item-head-left'"><span>{{
                            item.sender_id === curID ? '' : item.sender_name }}</span>&nbsp; <span>{{
                                    convertDateFormat(item.time) }}</span>
                        </div>
                        <n-popover :show-arrow="false" :show="item.able && (custom?.id ? true : false)"
                            :placement="item.sender_id === curID ? 'left-end' : 'right-end'" trigger="manual">
                            <template #trigger>
                                <div @touchstart="touchin(index)" @touchend="cleartime(item)"
                                    @mouseleave="touchblur(index)"
                                    :class="item.sender_id === curID ? 'item-cont-right' : 'item-cont-left'">
                                    <template v-if="item.type === 2">
                                        <n-image width='200' :src='item.content' />
                                    </template>
                                    <template v-else-if="item.type === 1">
                                        {{ item.content }}
                                    </template>
                                </div>
                            </template>
                            <div class="editTools">
                                <n-button @click="editMsg(item, 1)" text>
                                    <template #icon>
                                        <n-icon>
                                            <Copy />
                                        </n-icon>
                                    </template>
                                    复制
                                </n-button>
                            </div>
                            <div class="editTools">
                                <n-button @click="editMsg(item, 2)" text>
                                    <template #icon>
                                        <n-icon>
                                            <TrashSharp />
                                        </n-icon>
                                    </template>
                                    删除
                                </n-button>
                            </div>
                            <div class="editTools" v-if="ifDisableOfTime(item)">
                                <n-button @click="editMsg(item, 3)" text>
                                    <template #icon>
                                        <n-icon>
                                            <ArrowUndoSharp />
                                        </n-icon>
                                    </template>
                                    撤回
                                </n-button>
                            </div>
                        </n-popover>
                    </div>

                </template>
                <div style="height: 20px;"></div>
            </n-scrollbar>
        </div>
        <div class="home-bottom">
            <div class="inp">
                <div class="tmpicon" @click="showEmo">
                    <n-icon size="22" style="cursor: pointer;">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24">
                            <g fill="none">
                                <path
                                    d="M6.75 12a.75.75 0 0 0-.748.818C6.282 15.892 8.552 18.5 12 18.5c3.448 0 5.717-2.608 5.998-5.682A.75.75 0 0 0 17.25 12H6.749zM12 17c-2.257 0-3.871-1.48-4.368-3.5h8.736C15.872 15.52 14.258 17 12 17zm3.25-8.25c-.408 0-.71.287-.758.61a.75.75 0 1 1-1.484-.22c.162-1.09 1.123-1.89 2.242-1.89s2.08.8 2.242 1.89a.75.75 0 1 1-1.484.22c-.048-.323-.35-.61-.758-.61zm-7.258.61c.048-.323.35-.61.758-.61c.409 0 .71.287.758.61a.75.75 0 1 0 1.484-.22c-.161-1.09-1.123-1.89-2.242-1.89s-2.08.8-2.242 1.89a.75.75 0 0 0 1.484.22zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2zM3.5 12a8.5 8.5 0 1 1 17 0a8.5 8.5 0 0 1-17 0z"
                                    fill="currentColor"></path>
                            </g>
                        </svg>
                    </n-icon>
                </div>
                <n-input v-model:value="msgObj.content" :allow-input="noSideSpace" :placeholder="placeholder"
                    type="textarea" size="large" :autosize="{
                        minRows: 1,
                        maxRows: 2,
                    }" @focus="btmhandleBlur" />
                <div class="tmpicon" v-if="device">
                    <n-button :loading="sendbtnload" v-if="hasCont" type="success" @click="send()" size="large">
                        发送
                    </n-button>
                    <n-upload v-else :show-file-list="false" accept="image/*" v-model:file-list="file_list"
                        @before-upload="beforeUpload" @finish=uploadFinish @error="uploadFail"
                        :trigger-style="{ display: 'flex', alignItems: 'center' }"
                        :action="`/node/file/upload?user_id=${curID}`">
                        <n-button quaternary circle :loading="sendbtnload">
                            <template #icon>
                                <n-icon :size="25">
                                    <AddCircleOutline />
                                </n-icon>
                            </template>
                        </n-button>
                        <!-- <n-icon size="30" :component="AddCircleOutline" /> -->
                    </n-upload>

                </div>
            </div>
            <div class="systems" v-if="showEmoji && device">
                <EmojiPicker style="width: 100%;height: 327px" :display-recent="true" :native="true"
                    :disable-skin-tones="true" :hide-search="true"
                    :disabled-groups="['animals_nature', 'food_drink', 'activities', 'travel_places', 'objects', 'symbols', 'flags']"
                    @select="selectEmoji" />
            </div>
        </div>
        <n-drawer :auto-focus="false" :show="(device && active && isAdmin)" width="100%" placement="left">
            <div class="app-search">
                <n-input v-if="info_loading" ref="searchInp" placeholder="搜索用户" v-model:value="msgObj.search"
                    style="background-color: #fff;" clearable @input="handleInput">
                    <template #prefix>
                        <n-icon :component="SearchOutline" />
                    </template>
                </n-input>
            </div>
            <n-drawer-content :style="{ height: `calc(100% - 46px)` }">
                <n-list clickable>
                    <n-list-item v-for="item in user_list" :key="item.id" @click="getDetail(item)">
                        <div class="list">
                            <n-badge :value="getUnReadLength(item.list)">
                                <span class="icon">
                                    <n-icon size="32">
                                        <PersonOutline />
                                    </n-icon>
                                </span>
                            </n-badge>
                            <span class="user">
                                <div>{{ item.name }} </div>
                                <div class="overText">{{ isURL(getEllips(item.list)) ? '[图片]' : getEllips(item.list) }}
                                </div>
                            </span>
                        </div>
                    </n-list-item>
                </n-list>
                <div v-if="!info_loading" class="loadBox">
                    <div class="loaders">
                        <span class="loaderspan"></span>
                        <span class="loaderspan"></span>
                        <span class="loaderspan"></span>
                        <span class="loaderspan"></span>
                    </div>
                    <div> 拼命加载中...</div>
                </div>
                <n-float-button v-if="info_loading" @click="appLogout" :right="50" :bottom="50">
                    <n-icon>
                        <PowerOutline />
                    </n-icon>
                </n-float-button>
            </n-drawer-content>
        </n-drawer>
    </div>
    <n-modal v-model:show="showModal" title="提交内容" preset="dialog" positive-text="确认" negative-text="取消"
        @positive-click="onPositiveClick">
        <n-card :bordered="false">
            <n-checkbox-group @update:value="handleUpdateValue">
                <n-space item-style="display: flex;" align="center">
                    <n-checkbox v-for="item in formList" :value="item.path" :label="item.label" />
                </n-space>
            </n-checkbox-group>
        </n-card>
    </n-modal>
    <n-modal v-model:show="editModal" title="提交内容" preset="dialog" positive-text="确认" negative-text="取消"
        @positive-click="submitEdit">
        <n-form ref="infoRef" :model="model" :rules="info_rules">
            <n-form-item v-for="item in formList" :label="item.label">
                <n-input placeholder="" v-model:value="model[item['path']]" @keydown.enter.prevent />
            </n-form-item>
        </n-form>
    </n-modal>
</template>
<script setup>

import { computed, onBeforeMount, onMounted, reactive, h, ref, watch, nextTick } from "vue";
import {
    NButton, NInput, NIcon, NDrawer, NDrawerContent, NList, NListItem, NBadge, NScrollbar, useMessage, NUpload, NImage, NDivider, NSpin,
    NSplit, NPopover, NSpace, NCard, NForm, NFormItem, NModal, NCheckbox, NCheckboxGroup, NDropdown, NFloatButton, useDialog
} from "naive-ui"
import {
    ChevronBackOutline, Menu, SearchOutline, PersonOutline, AddCircleOutline, FolderOutline, EllipsisVertical, Copy, ArrowUndoSharp, CloudUploadOutline, PowerOutline,
    TrashSharp, EllipseSharp, LogOutOutline
} from '@vicons/ionicons5'
import { useStore } from "vuex"
import { useRouter } from 'vue-router';
import { decrypt } from "../utils/methods"
import { _get, _post } from "../utils/http"
import compressFile from "../utils/compressFile"
// import Recorder from 'recorder-core'
// import V3Emoji from 'vue3-emoji'
// import 'vue3-emoji/dist/style.css'
import EmojiPicker from 'vue3-emoji-picker'
import ScreenShort from "js-web-screen-shot";
import Axios from "axios"
import checkType from "../utils/file_types"

// import css
import 'vue3-emoji-picker/css'

// //引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
// import 'recorder-core/src/engine/mp3'
// import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
//import 'recorder-core/src/engine/wav'

//可选的插件支持项，这个是波形可视化插件
// import 'recorder-core/src/extensions/waveview'
function renderIcon(icon) {
    return () => {
        return h(NIcon, null, {
            default: () => h(icon)
        });
    };
}
let showEmoji = ref(false)
let emojiHeight = ref(0)
const dialog = useDialog()
const editModal = ref(false)
const message = useMessage()
const store = useStore()
const router = useRouter()
const showModal = ref(false)
let file_list = ref([])
const curUser = computed(() => store.state.user.user_info)//当前用户信息
const message_list = computed(() => store.state.home.message_list)//消息详情
const searchInp = ref()
let user_list = ref([])//消息列表（联系人）
let msgObj = reactive({
    content: "",
    search: ""
})
const dropOptions = [
    {
        label: "修改用户信息",
        key: 1,
        icon: renderIcon(PersonOutline)
    },
    {
        label: "提交线索",
        key: 2,
        icon: renderIcon(CloudUploadOutline)
    },
]
const dropSelect = (e, option) => {
    switch (e) {
        case 1:
            editModal.value = true
            break
        case 2:
            showModal.value = true
    }
}
const formList = [
    {
        path: "name",
        label: "姓名"
    },
    {
        path: "phone",
        label: "联系方式"
    },
    {
        path: "email",
        label: "邮箱"
    },
    {
        path: "dept",
        label: "单位"
    },
    {
        path: "address",
        label: "地址"
    },
    {
        path: "role",
        label: "职位"
    },
]
let infoRef = ref(null)
const model = reactive({
    name: "",
    phone: "",
    email: "",
    dept: "",
    role: "",
    address: "",
    admin: ""
})
let edit = ref(true)
let info_rules = reactive(
    {
        name: {
            required: false,
            message: '',
            trigger: ['input'],
        },
        phone: {
            required: false,
            message: '',
            trigger: ['input'],
        },
        email: {
            required: false,
            message: '',
            trigger: ['input'],
        },
        dept: {
            required: false,
            message: '',
            trigger: ['input'],
        },
        address: {
            required: false,
            message: '',
            trigger: ['input'],
        },
        role: {
            required: false,
            message: '',
            trigger: ['input'],
        },
    }
)
let sendbtnload = ref(false)
let submitRef = ref()
let moreTpe = ref(0)
let hasCont = ref(false)
let stage = {//socket请求状态码
    SUCCESS: 0,
    SOCKET_START: 1,
    SOCKET_END: 2,
    FAILED: 3,
    ERROR: 4,
    NOTICE: 5,
    MSG: 6,
    CUSTOMER: 7,
    START: 8
}
let video = reactive({
    rec: {},
    wave: ""
})
let filter_list = ref([])
const uploadRef = ref(null)
let perPage = ref(0)
let newsCount = ref(0)
const nScroll = ref()
const pcnScroll = ref()
let scroll_height = ref()
let checked = ref([])
let device = ref(true)//判断是否为移动端
let active = ref(false)//移动端消息列表
let isAdmin = ref(false)//是否为管理员
let curID = ref(null)//当前用户id
let custom = ref()//对话人信息
let cmt_id = ref()//客服id
let info_loading = ref(false)
let placeholder = ref("在此处输入内容")
const cttClose = () => {
    let ctt = document.getElementsByClassName("home-content")[0]
    ctt.style.height = `calc(100% - var(--home-top) - var(--home-bottom))`
    closeEmoji()
}
const btmhandleBlur = () => {
    setTimeout(function () {
        let ctt = document.getElementsByClassName("home-content")[0]
        ctt.style.height = `calc(100% - var(--home-top) - var(--home-bottom))`
    }, 400)
    closeEmoji()
}
const closeEmoji = () => {
    showEmoji.value = false
    setTimeout(function () {
        let btm = document.getElementsByClassName("home-bottom")[0]
        btm.style.height = `auto`;
    }, 900)

}
const showEmo = () => {
    showEmoji.value = !showEmoji.value
    let btm = document.getElementsByClassName("home-bottom")[0]
    let ctt = document.getElementsByClassName("home-content")[0]
    switch (showEmoji.value) {
        case true:
            const height = ctt.offsetHeight;
            ctt.style.height = height < 600 ? (height + "px") : (height - 320 + "px")
            btm.style.height = `auto`;
            setTimeout(function () {
                btm.style.height = `515px`;
            }, 600)
            scrollToBtm()
            break
        case false:
            ctt.style.height = `calc(100% - var(--home-top) - var(--home-bottom))`
            closeEmoji()
    }
}
const noSideSpace = (value) => !value.startsWith(" ") && !value.endsWith(" ")//不允许输入空白内容
const debounce = (func, delay) => {
    let timerId;
    return function (...args) {
        const context = this;
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            func.apply(context, args); // 执行 func，并传递所有参数
            timerId = null;
        }, delay);
    };
}
const changeHeight = (val) => {
    scroll_height.value = val
}
let loop = null
const touchin = (index) => {
    clearInterval(loop); //再次清空定时器，防止重复注册定时器
    loop = setTimeout(function () {
        message_list.value[index].able = true
    }, 700);
}
const cleartime = (index) => {
    // 这个方法主要是用来将每次手指移出之后将计时器清零
    clearInterval(loop);
}
const touchblur = (index) => {
    message_list.value[index].able = false
}
// 重新登录
const appLogout = () => {
    dialog.warning({
        title: "提示",
        content: "是否确定重新登录？",
        positiveText: "确定",
        negativeText: "取消",
        maskClosable: true,
        onPositiveClick: () => {
            localStorage.removeItem("token")
            router.push('/login')
        },
    });
}
const ifDisableOfTime = (item) => {
    if (item.sender_id === curID.value && isWithinTwoMinutes(item.time)) {
        return true
    }
    return false
}
function isWithinTwoMinutes(timestamp) {
    // 将时间字符串转换为 Date 对象
    const date = new Date(timestamp);

    // 获取当前时间的 Date 对象
    const now = new Date();

    // 计算两者之间的时间差（单位：毫秒）
    const diff = Math.abs(now - date);

    // 判断时间差是否在两分钟以内（120000 毫秒）
    return diff <= 120000;
}
const itemClick = (e) => {
    console.log(e);
}
//模糊搜索用户名函数
const ifNameIncludeStr = (str, name) => {
    let has = false
    for (let i = 0; i < str.length; i++) {
        for (let j = 0; j < name.length; j++) {
            if (name[j] === str[i]) {
                has = true
            }
        }
    }
    return has
}
// 用户列表页的模糊搜索
const searchUser = (val) => {
    // console.log(val);
    if (val !== "" && val !== undefined && val !== null) {
        let list = filter_list.value
        list = list.filter(item => {
            if (item.name && ifNameIncludeStr(val, item.name)) {
                return true
            }
        })
        // console.log(list);
        user_list.value = list
    } else {
        getMsg()
    }
}
const inputDebounce = (debounce(searchUser, 800))
const handleInput = (val) => {
    inputDebounce(val)
}
// 屏幕尺寸变化回调
const handleResize = () => {
    const debouncedHandleInput = (debounce(changeHeight, 0))
    let window_height = window.innerHeight + (262 * message_list.value.length)
    // console.log(window_height);
    debouncedHandleInput(window_height)
    let window_width = window.innerWidth
    if (window_width < 768) {
        device.value = true
    } else {
        device.value = false
    }
    // if (nScroll.value) {
    scrollToBtm()
    // }
}
onMounted(() => {
    active.value = true
    window.addEventListener('resize', handleResize);
    document.addEventListener('paste', handlePaste);
    // isAdmin.value = store.state.user.user_info.admin === '1' ? true : false
    curID.value = curUser.value.id
    getUserInfo()
    getVideo()
    handleResize()
    scrollToBtm()
})
const openSelectModal = () => {
    showModal.value = true
}
const handleUpdateValue = (value) => {
    let arr = checked.value
    console.log(value);
}
const onPositiveClick = () => {
    showModal.value = false
    editModal.value = false
}
const onNegativeClick = () => {
    showModal.value = false
}
// 编辑
const editMsg = (item, count) => {
    switch (count) {
        case 1:
            getClipboard(item)
            break;
        case 2:
            let params = { user_id: curID.value, msg_id: item.id, type: item.type }
            _post("/node/msg/delMsg", params).then(res => {

                if (res.code === 200) {
                    message.success("删除成功！")
                    getMsg()
                } else {
                    message.warning("删除失败！")

                }
            })
            break;
        case 3:
            let msg_id = item.id
            _post("/node/msg/backMsg", { msg_id, type: item.type }).then(res => {
                if (res.code === 200) {
                    message.success("撤回成功！")
                    wss.wssInstance.send(JSON.stringify({ stage: stage.MSG, data: { sender_id: curID.value, receiver_id: custom.value.id } }))
                    getMsg()
                } else {
                    message.warning("撤回失败！")
                }
            })
    }
}
const base64ToFile = (base64Data, fileName = "截图文件.png") => {
    const arr = base64Data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}
const handlePaste = (event) => {
    // console.log(event);
}
const selectEmoji = (emoji) => {
    msgObj.content += emoji.i
}
const inpFocus = (e) => {
    message.success("获取了焦点")
}
// 录音功能
const recOpen = () => {
    //创建录音对象
    video.rec = Recorder({
        type: "mp3" //录音格式，可以换成wav等其他格式
        , sampleRate: 16000 //录音的采样率，越大细节越丰富越细腻
        , bitRate: 16 //录音的比特率，越大音质越好
        , onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) => {
            //录音实时回调，大约1秒调用12次本回调
            //可实时绘制波形，实时上传（发送）数据
            if (video.wave) video.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
        }
    });

    //打开录音，获得权限
    video.rec.open(() => {
        message.info("录音已打开");

    }, (msg, isUserNotAllow) => {
        //用户拒绝了录音权限，或者浏览器不支持录音
        message.info((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
    });
}
const handleKeydown = (event) => {

    if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        // console.log(event.key);
        send()
    } else if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        const cursorPosition = event.target.selectionStart;
        const value = msgObj.content;
        const before = value.substring(0, cursorPosition);
        const after = value.substring(cursorPosition);

        // 在当前光标位置插入换行符
        msgObj.content = `${before}\n${after}`;
        // 调整光标位置（移动到换行后的位置）
        nextTick(() => {
            event.target.selectionStart = event.target.selectionEnd = cursorPosition + 1;
        });
    }
}
const beforeUpload = async (data) => {
    let type = data.file.file?.type
    sendbtnload.value = true
    let chk = checkType(type)
    if (!chk) {
        message.warning("不被允许的文件类型！")
        sendbtnload.value = false
        return false
    }
    let blobFile = data.file.file
    await compressFile(blobFile).then(file => {
        data.file.file = file
        // console.log(file);
        // console.log("before:", Date.now());
    })
    // return true
}
// 结束后
const uploadFinish = ({ event }) => {
    let result = JSON.parse(event.target.response)
    let data = result.data.file
    if (data.url) {
        msgObj.content = data.url
        isImg.value = 2
        // console.log("finish:", Date.now());
        send()
        sendbtnload.value = false
    } else {
        isImg.value = 1
    }
}
//上传失败
const uploadFail = () => {
    message.error("上传失败！")
    sendbtnload.value = false
    isImg.value = 1
}
const back = () => {
    active.value = true
    let ctt = document.getElementsByClassName("home-content")[0]
    ctt.style.height = `calc(100% - var(--home-top) - var(--home-bottom))`
    closeEmoji()
}

const getVideo = () => {


}
// 获取更多聊天记录
const getMore = () => {
    moreTpe.value = 1
    perPage.value = perPage.value + 1
    let params = { sender_id: curID.value, receiver_id: custom.value.id, perPage: perPage.value }
    _post("/node/msg/getMore", params).then(res => {
        if (res.code === 200) {
            let list = res.data.list
            if (list.length > 0) {
                let msgList = store.state.home.message_list
                list = list.concat(msgList)
                store.dispatch("getMsg", list)
                moreTpe.value = 0
            } else {
                moreTpe.value = 2
            }
        }
    })

}
let touchTimer = null
const handleTouchStart = (event) => {
    touchTimer = setTimeout(() => {
        // 在这里执行长按后的操作，比如显示自定义菜单
        console.log('Long press detected');
        // 显示自定义菜单的逻辑
    }, 500); // 设置长按的时间阈值，单位为毫秒
}
const cancleSubmit = () => {
    let info = custom.value
    for (let i in model) {
        model[i] = info[i]
    }
    edit.value = true
}
const submitEdit = () => {
    const { name, phone, address, dept, role, admin, email } = model
    let id = custom.value.id
    _post("/node/user/edit", { id, name, phone, address, dept, role, admin, email }).then(res => {
        if (res && res.code === 200) {
            message.success("修改成功！")
            getMsg()
        } else {
            message.warning("失败！")
        }
        edit.value = true
    })
}
// 处理截图
const cutScreen = () => {
    setTimeout(function () {
        const screenShotHandler = new ScreenShort({
            enableWebRtc: false,  //是否显示选项框
            loadCrossImg: true,//是否显示跨域图片
            proxyUrl: "http://mqfile.znjs.com:6901/sales_customer",
            level: 99,  //层级级别
            completeCallback: getImg,
            closeCallback: destroyComponent,
        });
    }, 0)
}
// 销毁组件函数
const destroyComponent = function () {
    // console.log("关闭了截图");
}
// 获取裁剪区域图片信息
const getImg = function (imgObj) {
    let base64 = imgObj.base64
    let file = base64ToFile(base64)
    // 创建 FormData 对象来封装文件数据
    const formData = new FormData();
    formData.append('file', file);
    // console.log(file);
    Axios.post(`/node/file/upload?user_id=${curID.value}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            transformRequest: [data => data]
        }
    }).then(res => {
        if (res.data && res.data.code === 200) {
            let data = res.data.data
            let url = data.file.url
            msgObj.content = url
            isImg.value = 2
            send()
            // console.log(res.data.file.url);
        }
    })
}
// 获取剪贴板数据
const getClipboard = async (item) => {
    if (!navigator.clipboard) {
        var input = document.createElement("input");
        input.value = item.content; // 要复制的文本框的内容（此处是后端返回的内容）
        document.body.appendChild(input);
        input.select(); // 选中文本
        document.execCommand("copy");
        document.body.removeChild(input);
        message.success("复制成功!");
    } else {
        try {
            await navigator.clipboard.writeText(item.content);
            message.success("复制成功！");
        } catch (err) {
            message.warning("复制失败！");
        }
    }
}

watch(msgObj, (newVal) => {
    if (newVal.content.length > 0) {
        hasCont.value = true
    } else {
        hasCont.value = false
    }
})
const name = ref(null)
let clickedKey = ref(null)
// 点击记录详情
const getDetail = (item, index) => {
    // console.log(item);
    let doms = document.getElementsByClassName("lil")
    for (let i = 0; i < doms.length; i++) {
        if (i === index) {
            doms[i].style.backgroundColor = "#c4c3c4"
        } else {
            doms[i].style.backgroundColor = "#e8e7e6"
        }
    }
    active.value = false
    custom.value = item
    name.value = item.name
    store.dispatch("getMsg", item.list)
    if (!clickedKey || clickedKey.value != item.id) {
        clickedKey.value = item?.id
        readMsg(item.id)
        wss.wssInstance.chating = true
        moreTpe.value = 0
        perPage.value = 0
        for (let i in model) {
            model[i] = item[i]
        }
        // scrollToBtm()
    }
    handleResize()
}
const getFocus = () => {
    let textarea = document.getElementsByClassName("custom-textarea")[0]
    textarea.focus();
}
watch(user_list, (newVal) => {
    let count = 0
    for (let i = 0; i < newVal.length; i++) {
        let list = newVal[i].list
        for (let j = 0; j < list.length; j++) {
            if (list[j] && list[j].sender_id !== curID.value && custom.value && list[j].sender_id !== custom.value.id && list[j].status === 0) {
                // console.log(custom.value);
                count++
            }
        }
    }
    newsCount.value = count
})
// 修改已读状态
const readMsg = (id) => {
    if (id) {
        _get(`/node/msg/readMsg?id=${id}`).then(res => {
            if (res.code !== 200) {
                message.info("该用户已被删除！")
            } else if (res.code === 200) {
                getMsg()
            }
            getFocus()
        })
    } else {
        message.info("该用户已被删除！")
    }
}
// 滚动到最低部
const scrollToBtm = () => {
    setTimeout(function () {
        if (nScroll.value) {
            let scrollTo = nScroll.value.scrollTo
            scrollTo({
                top: scroll_height.value + 260,
                behavior: 'instant' // 平滑滚动
            })
        }
    }, 10)
    setTimeout(function () {
        if (pcnScroll.value) {
            let scrollTo = pcnScroll.value.scrollTo
            scrollTo({
                top: scroll_height.value + 260,
                behavior: 'instant' // 平滑滚动
            })
        }
    }, 10)
}
// 判断是否为http链接
const isURL = (str) => {
    // 匹配 URL 的正则表达式
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(str);
}
// 获取未读条数
const getUnReadLength = (list) => {
    let length = list.filter(item => {
        if (item && item.sender_id !== curID.value && item.status === 0) {
            return true
        }
    }).length
    return length
}
// 获取消息列表
const getMsg = () => {
    return new Promise((resolve, reject) => {
        _post("/node/msg/getMsg", { id: curID.value, perPage: 0 }).then(res => {
            if (res && res.code === 200 && res.data) {
                let list = res.data.list
                user_list.value = list
                filter_list.value = list
                if (isAdmin.value) {
                    getCurMsgList()
                    // store.dispatch("getMsg", list)
                    resolve(1)
                } else {
                    store.dispatch("getMsg", list[0].list)
                    custom.value = list[0]
                    name.value = list[0].name
                    // console.log(custom.value);
                }
                // console.log(moreTpe.value, active.value, message_list.value);
                // 
                handleResize()
            } else {
                reject()
                message.warning("错误!")
            }
        }).catch(err => {
            console.log("err:", err);
        })
    })

}
const getCurMsgList = () => {
    let list = user_list.value
    let cur_custom = custom.value
    if (cur_custom && cur_custom.id) {
        let curli_id = cur_custom.id
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === curli_id) {
                store.dispatch("getMsg", list[i].list)
                custom.value = list[i]
                // console.log(custom.value);
            }
        }
    }
}
let isImg = ref(1)
// 发送消息
const send = () => {
    let msg = msgObj.content
    if (msg && msg.trim() !== "") {
        sendbtnload.value = true
        let params
        if (!isAdmin) {
            let custom_id = custom.value?.id
            params = { sender_id: curID.value, sender_name: curUser.value.name, receiver_id: custom_id, receiver_name: custom.value.name, content: msg, type: isImg.value }
        } else {
            params = { sender_id: curID.value, sender_name: curUser.value.name, receiver_id: custom.value.id, receiver_name: custom.value.name, content: msg, type: isImg.value }
        }
        // wss.wssInstance.send(JSON.stringify({ stage: stage.START, data: params }))
        _post("/node/msg/send", { ...params }).then(res => {
            if (res && res.code === 200) {
                msgObj.content = ""
                let ls = store.state.home.message_list
                ls.push(res.data.msg)
                store.dispatch("getMsg", ls)
                wss.wssInstance.send(JSON.stringify({ stage: stage.MSG, data: res.data.msg }))
                getMsg()
                scrollToBtm()
            } else {
                message.warning("错误！")
            }
            isImg.value = 1
            sendbtnload.value = false
            closeEmoji()
        })
    } else {
        message.warning("不可发送空内容！")
    }
}
const getMsgType = (str) => {
    let type = 0
    if (isURL(str)) {
        type = 2
    } else {
        type = 1
    }
    return type
}
const getEllips = (list) => {

    if (list && list.length > 0) {
        return list[list.length - 1].content
    } else {
        return ''
    }
}
const convertDateFormat = (isoDateString) => {
    // 将 ISO 8601 格式时间字符串转换为 Date 对象
    const date = new Date(isoDateString);

    // 提取年、月、日、小时和分钟
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月份从0开始，需要加1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 构造需要的格式
    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;

    return formattedDate;
}

let wss = reactive({
    wssInstance: null
})

const getUserInfo = () => {
    active.value = true
    let id = curID.value
    _get(`/node/user/getInfo?id=${id}`).then(res => {
        if (res && res.code === 200) {
            decrypt(res.data.user).then(info => {
                // console.log(info);
                let user = JSON.parse(info)
                let params = { user_info: user, isLogin: true }
                store.dispatch("userLogin", params)
                if (user.admin !== "1") {
                    isAdmin.value = false
                    active.value = false
                    cmt_id.value = user.receiver
                } else {
                    isAdmin.value = true
                    active.value = active.value === true ? true : false
                    searchInp.value?.blur();
                }
                Promise.resolve(1).then(() => {
                    cont()
                    getMsg()
                    handleResize()
                    info_loading.value = true
                })
            }).catch(error => {
                console.log(error);
            })


        } else {
            message.warning("未找到该用户，请重新登录！")
            localStorage.removeItem("token")
            router.push("/login")
        }
    })
}
const cont = () => {
    let ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET}`);
    wss.wssInstance = ws;
    ws.onopen = () => {
        let token = localStorage.getItem("token")
        if (token) {
            decrypt(token).then(info => {
                let user = JSON.parse(info)
                let userid = user ? user.id + "" : ""
                let userInfo = { userid, receiver: cmt_id.value }
                // console.log(user);
                if (user.admin) {
                    userInfo.admin_id = userid
                }
                if (userid) {
                    ws.send(JSON.stringify({ stage: stage.START, data: { ...userInfo } }))
                } else {
                    message.error("认证错误，请重新登录，多次失败请联系管理员！")
                    router.push("/login")
                }
            })
        } else {
            router.push("/login")
        }
        // store.dispatch("setNews", store.state.head.news ? store.state.head.news : {})
        // console.log("open");
    }
    ws.onmessage = (msg) => {
        let result = JSON.parse(msg.data)
        let data
        // console.log(result);
        switch (result.stage) {
            case 0:
                break;
            case stage.CUSTOMER:
                data = result.data
                if (!isAdmin.value) {
                    custom.value = data
                }
            // console.log(data);
            case stage.MSG:
                // console.log(result);
                data = result.data
                let sender_id = data.sender_id
                if (sender_id) {
                    getMsg().then(() => {
                        if (custom.value) {
                            // console.log(custom.value);
                            readMsg(sender_id)
                            handleResize()
                        }
                    })
                }
        }
    };
    ws.onclose = () => {
        let curRouter = router.currentRoute.value?.fullPath
        if (curRouter === "/home") {
            setTimeout(function () {
                cont()
            }, 1500)
        }
        // console.log("close");
        // setTimeout(conectToSocket(), 2000)
    }
    ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        message.error("网络链接失败！")
    };
}



</script>
<style>
@media screen and (min-width:768px) {
    .home {
        display: none;
        /* background-color: aquamarine; */
    }

    .pc-home {
        width: 100%;
        height: 100%;
    }

    .pc-home-left {
        /* width: 100%; */
        height: 100%;
        /* background-color: #95ec69; */
    }

    .pc-left {
        display: flex;
        flex-direction: row;
        justify-content: start;
        width: 100%;
        height: 100%;
        background-color: #e8e7e6;
    }

    .pc-left>span:nth-child(1) {
        width: calc(100%);
        height: 100%;
    }

    .list {
        width: 100%;
        height: 46px;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .pc-home-btm {
        padding: 10px 24px 24px 24px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .pc-home-btm>.inp-box {
        margin: 10px 0;
        height: calc(100% - 116px);
    }

    .pc-home-btm>.submit {
        text-align: right;
    }

    .item-cont-left {
        display: inline-block;
        max-width: 60%;
        padding: 10px 10px;
        border-radius: 5px;
        margin-left: 10px;
        background-color: #fff;
        font-size: var(--detail-msg-cont-fs);
    }

    .item-cont-right {
        display: inline-block;
        max-width: 60%;
        padding: 10px 10px;
        border-radius: 5px;
        margin-right: 18px;
        /* float: right; */
        clear: right;
        background-color: #95ec69;
        /* 清除右侧的浮动，用于换行后的第一个子元素 */
        text-align: left;
        /* 新行子元素靠左对齐 */
        height: auto;
        overflow-wrap: break-word;
        font-size: var(--detail-msg-cont-fs);
    }

    .edit-icon-right {
        /* padding: 12px 0 10px 0; */
        height: 20px;
        display: flex;
        align-items: end;
        position: absolute;
        left: -18px;
        bottom: 0;
        color: #d4d4d4;
    }

    .edit-icon-left {
        /* padding: 12px 0 10px 0; */
        height: 20px;
        display: flex;
        align-items: end;
        position: absolute;
        right: -18px;
        bottom: 0;
        color: #d4d4d4;
    }

    .edit-icon-right:hover,
    .edit-icon-left:hover {
        color: #000;
    }

    .item-cont-right>.edit-icon {
        float: left;
    }

    .item-cont-left>.edit-icon {
        float: right;
    }

    .pc-search {
        height: var(--pc-home-top-height);
        display: flex;
        align-items: center;
        background-color: #f7f7f7;
        border-bottom: 1px solid #e0e0e6;
        padding: 0 15px;
    }
}

@media screen and (max-width:768px) {
    .pc-home {
        display: none
    }

    .home {
        width: 100%;
        height: 100%;
        /* background-color: aquamarine; */
    }

    .home-left {
        display: none;
    }

    .home-top {
        width: 100%;
        height: var(--home-top);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #efeff5;
        padding: 0 5px;
    }

    .home-top-c {
        width: 100%;
        height: var(--home-top);
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #efeff5;
        padding: 0 5px;
    }

    .pc-home-btm {
        display: none;
    }

    .home-top>span {
        display: flex;
        align-items: center;
        /* width: 150px; */
        justify-content: center;
        /* margin: 0 25px; */
    }

    .top-back {
        margin-left: 6px;
    }

    .top-edit {
        margin-right: 8px;
    }

    .home-bottom {
        width: 100%;
        border-top: 1px solid #efeff5;
        background-color: #fff;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
    }

    .inp {
        width: 100%;
        height: var(--home-bottom);
        border-top: 1px solid #efeff5;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        transition: all 0.5s ease;
    }

    .systems {
        width: 100%;
        height: 500px;
        background-color: #fff;
    }

    .tmpicon {
        margin: 0 16px;
        font-size: 26px;
        display: flex;
        align-items: center;
    }

    .list {
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .item-cont-left {
        user-select: none;
        display: inline-block;
        max-width: 60%;
        padding: 10px 10px;
        border-radius: 5px;
        margin-left: 9.5px;
        background-color: #fff;
        font-size: var(--detail-msg-cont-fs);
    }

    .item-cont-right {
        user-select: none;
        display: inline-block;
        max-width: 60%;
        padding: 10px 10px;
        border-radius: 5px;
        margin-right: 18px;
        /* float: right; */
        clear: right;
        background-color: #95ec69;
        /* 清除右侧的浮动，用于换行后的第一个子元素 */
        text-align: left;
        /* 新行子元素靠左对齐 */
        height: auto;
        overflow-wrap: break-word;
        font-size: var(--detail-msg-cont-fs);
    }

}

.pc-search {
    height: var(--pc-home-top-height);
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e0e0e6;
    padding: 0 15px;
}

.app-search {
    height: 46px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e0e0e6;
    padding: 0 15px;
}

.icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

/* .list:active { */
/* background-color: #c8c5c4; */
/* } */

.list>.user {
    box-sizing: border-box;
    margin-left: 14px;
}

.list>.user>div:nth-child(1) {
    font-size: var(--list-user-first-fs);
}

.list>.user>div:nth-child(2) {
    font-size: var(--list-user-sec-fs);
    color: #9f9f9f;
}

.list>.pc-user {
    box-sizing: border-box;
    margin-left: 14px;
}

.list>.pc-user>div:nth-child(1) {
    font-size: var(--pc-list-user-first-fs);
}

.list>.pc-user>div:nth-child(2) {
    font-size: var(--pc-list-user-sec-fs);
    color: #9f9f9f;
}

.home-content {
    width: 100%;
    height: calc(100% - var(--home-top) - var(--home-bottom));
    /* display: flex;
        justify-content: end; */
    /* justify-content: center; */
    /* flex-direction: column; */
    /* background-color: bisque; */
    /* transition: all 0.2s; */
}


.pc-home-content {
    width: 100%;
    height: calc(100% - var(--pc-home-top-height));
    padding: 15px 5px 15px 15px;
}

.pc-home-top {
    width: 100%;
    height: var(--pc-home-top-height);
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1.5px solid #e0e0e6;
    text-align: left;
    padding: 25px;
    font-size: 17px;
    font-weight: 500;
}

.item-div-left {
    width: 100%;
    margin-top: 16px;
}

.item-div-right {
    width: 100%;
    margin-top: 16px;
    text-align: right;
}

.item-div-left>.item-head-left {
    padding-left: 10px;
    font-size: var(--detail-msg-head-fs);
    color: #9f9f9f;
}

.item-div-right>.item-head-right {
    padding-right: 18px;
    display: flex;
    flex-direction: row-reverse;
    font-size: var(--detail-msg-head-fs);
    color: #9f9f9f;
}

.top-title {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.custom-textarea {
    border: none;
    resize: none;
    outline: none;
    background-color: #f5f5f5;
    /* 禁止用户调整文本框大小 */
    width: 100%;
    /* 宽度100%，填充父元素宽度 */
    font-family: Arial, sans-serif;
    font-size: 16px;
    /* 可根据需要修改字体 */
    line-height: 1.3;
    /* 行高 */
    /* border: 1px solid transparent; */
    /* 设置明确的边框宽度和颜色 */
    padding: 0;
    /* 内边距 */
    box-sizing: border-box;
    /* 让padding不影响宽度 */
    overflow-y: auto;
    height: 100%;
    /* 隐藏多余的文本 */
    /* min-height: calc(1.3em * 6);
    max-height: calc(1.3em * 9); */
}

.loadBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadBox>div {
    display: flex;
    justify-content: center;
}

.loaders {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 6em;
    margin-bottom: 24px;
}

.loaderspan {
    width: 0.6em;
    height: 1.5em;
    background-color: #3cefff;
}

.item-cont-right,
.item-cont-left {
    white-space: pre-wrap;
}

.loaderspan:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
}

.loaderspan:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
}

.loaderspan:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
}

.loaderspan:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
}

@keyframes grow {

    0%,
    100% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(2);
    }
}
</style>