import request from "./request"

const _get = (url, params) => {
    const config = {
        method: 'get',
        url: url
    }
    if (params) config.params = params
    return request(config)
}
const _post = (url, params, headers = {}) => {
    const config = {
        method: 'post',
        url: url,
        headers: headers // 使用传入的headers
    }
    if (params) config.data = params
    return request(config)
}

export {
    _get, _post
}

