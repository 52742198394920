<template>
    <div class="login">
        <div class="logo">
            <img src="../images/mq.svg" alt="">
        </div>
        <div class="login_form">
            <div class="login-title">
                <span class="logo-title">设计项目信息提供平台</span>
            </div>
            <div class="input">
                <n-input-group>
                    <n-input-group-label>+86</n-input-group-label>
                    <n-input v-model:value="formValue.tel_number" placeholder="手机号码" />
                </n-input-group>
                <n-input-group style="margin-top: 28px;">
                    <n-input v-model:value="formValue.code" placeholder="请输入验证码" />
                    <n-spin size="small" :show="sending">
                        <n-button v-if="!active" @click="sendCode" type="success">
                            <span>发送验证码</span>
                        </n-button>
                    </n-spin>

                    <n-button type="success" v-if="active" :disabled="true">
                        重新发送
                        <n-countdown @finish="cutFinish" :render="renderCountdown" :duration="60000"
                            :active="active" />s
                    </n-button>
                </n-input-group>
            </div>
            <div class="log-btn">
                <n-button @click="login" block type="primary">登录/注册</n-button>
            </div>
        </div>
    </div>
    <div style="width: 100%;position: absolute;left:0;bottom: 0;">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
        </svg>
    </div>
</template>
<script setup>

import { ref, reactive, onMounted, watch } from "vue"
import { NForm, NButton, NInput, NFormItem, useMessage, NInputGroup, NInputGroupLabel, NCountdown, NSpin } from "naive-ui"
import { _get, _post } from "../utils/http"
import { checkIsAdmin, decrypt } from "../utils/methods"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const message = useMessage()
let formValue = reactive({
    tel_number: "",
    code: ""
})
let sending = ref(false)
let active = ref(false)
const renderCountdown = ({
    seconds
}) => {
    return `${String(seconds).padStart(2, "0")}`;
};
const cutFinish = () => {
    active.value = false
}
const isPhoneNumber = (str) => {
    // 定义手机号码的正则表达式
    const regExp = /^1[3456789]\d{9}$/;
    return regExp.test(str);
}

const login = () => {
    const { tel_number, code } = formValue
    if (!isPhoneNumber(tel_number) || code.length < 4) {
        message.warning("请输入正确的四位验证码！")
    } else {
        _post("/node/user/checkCode", { tel_number, code }).then(res => {
            if (res.code === 200) {
                localStorage.setItem("token", res.data.user)
                setTimeout(function () { router.push("/home") }, 0)
            } else {
                message.error(res.data.errmsg)
            }
        })
    }

}
const sendCode = () => {

    let tel_number = formValue.tel_number
    if (isPhoneNumber(tel_number)) {
        sending.value = true
        _post("/node/user/sendCode", { tel_number }).then(res => {
            if (res.code === 200) {
                message.success("发送成功,请在手机查收")
                active.value = true
                sending.value = false
            } else {
                message.error(res.data.errmsg)
                sending.value = false
            }
        })
    } else {
        message.warning("请输入正确的手机号码！")
    }

}
onMounted(() => {

})
</script>
<style>
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
}

/* @media screen and (min-width:768px) {
    .login_form {
        background-color: #fff;
    }
} */

@media screen and (max-width:768px) {

    .login_form {
        width: 100%;
        background-color: transparent !important;
    }
}

.logo {
    width: 148.02px;
    height: 35px;
    position: fixed;
    left: 33px;
    top: 28px;
    z-index: 9;
}

.logo-title {
    margin-left: 15px;
    height: 44px;
    line-height: 44px;
}

.login-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
    padding: 10px;
}

.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.login::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(60deg,
            rgba(245, 71, 71, 0.51) 0%,
            rgba(58, 97, 255, 0.585) 100%);
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

.login_form {
    width: 440px;
    height: 500px;
    padding: 40px;
    border-radius: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    background-color: #fff;
    /* 使用backdrop-filter实现模糊，可以根据需要调整模糊半径 */
    /* 调整透明度，突出背景模糊效果 */
}

.input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin-top: 30px;
}

.log-btn {
    margin-top: 28px;
    width: 80%;
    display: flex;
    justify-content: center;
}
</style>