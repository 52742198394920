export default {
    state() {
        return {
            message_list: [],
            isHome: false
        }
    },
    actions: {
        getMsg({ commit }, list) {
            commit("SETMSG", list)
        },
        setIsHome({ commit }, val) {
            commit("SETISHOME", val)
        }
    },
    mutations: {
        SETMSG(state, list) {
            state.message_list = list
        },
        SETISHOME(state, val) {
            state.isHome = val
        }
    }

}