import { createStore } from 'vuex'
import home from "./module/home"
import user from "./module/user"

export default createStore({
  state: {
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    /**
     * app全局参数
     */
    home,
    user
  }
})

