let types = [
    "image/jpeg",
    "image/png",
    "image/gif",
    // "image/bmp",
    // "image/webp",
    // "image/svg+xml"
]
export default function checkType(type) {
    return types.includes(type)
}