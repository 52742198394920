import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vfonts/FiraCode.css'
import { decrypt } from "./utils/methods"


window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}
// 禁用开发模式
// const app = createApp(App, {
//     productionTip: true
// });
const app = createApp(App);

// app.config.errorHandler = () => null;
// // 屏蔽警告信息
// app.config.warnHandler = () => null;
document.title = "中南客服"
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("token");
    checkIsHome(to.path);
    if (to.path === "/login") {
        // 如果用户尝试访问登录页面，但已经登录（有 token），则重定向到首页  
        if (token) {
            next("/home");
        } else {
            next(); // 允许访问登录页面  
        }
    } else {
        // 对于非登录页面，检查 token  
        if (token) {
            // 这里可以添加异步验证 token 的逻辑（如果需要）  
            // 但为了简化，我们假设 token 总是有效的  
            decrypt(token).then(res => {
                let user = JSON.parse(res);
                let params = { user_info: user, isLogin: true };
                store.dispatch("userLogin", params);
                next(); // 移动到异步操作完成后  
            }).catch(error => {
                console.log(error);
                // 可以选择重定向到错误页面或保持当前页面  
                next(false); // 阻止路由跳转  
            });
            // next(); // 允许访问受保护的页面  
        } else {
            // 如果没有 token，重定向到登录页面  
            router.push("/login");
        }
    }
});
router.afterEach((to, from) => {
})
function checkIsHome(path) {
    if (path === "/home") {
        store.dispatch("setIsHome", true)
    } else {
        store.dispatch("setIsHome", false)
    }
}
app.use(store).use(router).mount('#app')
